import Link from 'next/link'
import { MenuList, MenuItem, Typography } from '@mui/material'

// Types
import type { APIMenuItem } from '@/types/api/items/menu/menu'
import type { ListItemsProps } from '@/types/components/molecules/items/list'

const ListItemsMolecule = (props: ListItemsProps) => {
	// Props
	const { items, parentId } = props

	return (
		<MenuList>
			{items.map((item, index) => {
				const id = item.id || item._id

				if ((!parentId && item.parent_id === null) || (parentId && item.parent_id === parentId)) {
					let childrenItems: APIMenuItem[] = []

					// Find children
					childrenItems = items.filter(child => child.parent_id === id)

					return (
						<MenuItem key={index} className={childrenItems.length ? 'has-children' : ''}>
							<Link href={item.link_value ?? '/'}>
								{item.title}
								{parentId && childrenItems.length > 0 && (
									<Typography component="span" pl={2}>
										({childrenItems.length})
									</Typography>
								)}
							</Link>

							{childrenItems.length > 0 && <ListItemsMolecule items={items} parentId={id} />}
						</MenuItem>
					)
				} else return null
			})}
		</MenuList>
	)
}

export default ListItemsMolecule
